               
<template>
  <div class="root">
    <div class="critere_container text-center">
      <div class="feuille"><img src="../assets/feuille.svg" /></div>
      <h1 class="main_title">{{ lang.resultats_title }}</h1>
      <div class="subtitle my-4">
        <strong>{{ lang.resultats_subtitle }}</strong>
      </div>
      <div class="row">
        <div class="col-md-8 mx-auto row mb-4">
          <div class="col-6">
            <div class="encart_res">
              <h3>{{ lang.vos_points }}</h3>
              <div class="res">{{ points.total }}</div>
            </div>
          </div>
          <div class="col-6">
            <div class="encart_res">
              <h3>{{ lang.votre_palier }}</h3>
              <div class="res">{{ palier }}</div>
            </div>
          </div>
          <div class="col-12">
            <div class="encart_res">
              <h3>{{ lang.votre_statut }}</h3>
              <div class="res small">{{ statut }}</div>
            </div>
          </div>

          <div class="col-12">
            <div class="encart_res text-start mb-1">
              <h3>{{ lang.votre_remuneration }}</h3>
              <div class="res">
                {{ remuneration }} <small>{{ lang.par_h_brut }}</small>
              </div>
            </div>
            <p class="text-start text-muted">
              {{lang.remuneration_legende}}
              <!--{{ lang.anciennement }} {{ old_remuneration }}
              {{ lang.par_h_brut }}-->
            </p>
          </div>
          <div class="col-12">
            <h4>{{ lang.bon_a_savoir }}</h4>
            <div class="encart_savoir">
              <p v-html="lang.paye_plus"></p>
              <p v-html="lang.paye_moins"></p>
              <p><span v-html="lang.comprendre"></span> <router-link to="/nous-contacter">{{lang.contact_label}}</router-link></p>
            </div>
          </div>
          <div class="col-12 mb-4">
            <h4>{{ lang.detail_calcul }}</h4>
            <table class="table table-striped mb-4">
              <thead>
                <tr>
                  <th></th>
                  <th>{{ lang.points }}</th>
                  <th>{{ lang.degres }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-start">{{ lang.critere1_name }}</td>
                  <td>{{ points.points[0] }}</td>
                  <td>{{ points.level[0] + 1 }}</td>
                </tr>
                <tr>
                  <td class="text-start">{{ lang.critere2_name }}</td>
                  <td>{{ points.points[1] }}</td>
                  <td>{{ points.level[2] + 1 }}</td>
                </tr>
                <tr>
                  <td class="text-start">{{ lang.critere3_name }}</td>
                  <td>{{ points.points[2] + points.points[3] }}</td>
                  <td>{{ points.level[2] + 1 }} - {{ points.level[3] + 1 }}</td>
                </tr>
                <tr>
                  <td class="text-start">{{ lang.critere4_name }}</td>
                  <td>{{ points.points[4] }}</td>
                  <td>{{ points.level[4] + 1 }}</td>
                </tr>
                <tr>
                  <td class="text-start">{{ lang.critere5_name }}</td>
                  <td>{{ points.points[5] }}</td>
                  <td>{{ points.level[5] + 1 }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td class="text-start">{{ lang.total }}</td>
                  <td>{{ points.total }}</td>
                  <td>{{ palier }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <div class="bottom-bar">
        <div class="container">
          <div class="row">
            <a
              href="#"
              class="btn btn-secondary btn-block"
              data-bs-toggle="modal"
              data-bs-target="#enqueteModal"
              >{{ lang.retour }}</a
            >
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      tabindex="-1"
      id="enqueteModal"
      data-bs-backdrop="static"
      aria-labelledby="enqueteModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ lang.popup_title }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" v-html="lang.popup_content"></div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn"
              data-bs-dismiss="modal"
              @click="this.$router.push('/')"
            >
              {{ lang.non_merci }}
            </button>
            <a href="https://www.echanges-fga-cfdt.fr/fcrm-form/production-agricole_cuma_etarf/" targe="_blank" class="btn btn-primary" @click="this.$router.push('/')">
              {{ lang.je_participe }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "resultat",
  data() {
    return {
      cnum: 7,
    };
  },
  computed: {
    lang() {
      let langIndex = this.$store.state.lang;
      return this.$store.state.languages[langIndex];
    },
    points() {
      let c1_index = this.$store.state.critere1 - 1;
      let c2_index = this.$store.state.critere2 - 1;
      let c31_index = this.$store.state.critere31 - 1;
      let c32_index = this.$store.state.critere32 - 1;
      let c4_index = this.$store.state.critere4 - 1;
      let c5_index = this.$store.state.critere5 - 1;

      let c1_points = this.$store.state.critere1_points[c1_index];
      let c2_points = this.$store.state.critere2_points[c2_index];
      let c31_points = this.$store.state.critere31_points[c31_index];
      let c32_points = this.$store.state.critere32_points[c32_index];
      let c4_points = this.$store.state.critere4_points[c4_index];
      let c5_points = this.$store.state.critere5_points[c5_index];

      let computedPoints =
        this.$store.state.critere1_points[c1_index] +
        this.$store.state.critere2_points[c2_index] +
        this.$store.state.critere31_points[c31_index] +
        this.$store.state.critere32_points[c32_index] +
        this.$store.state.critere4_points[c4_index] +
        this.$store.state.critere5_points[c5_index];
      if (isNaN(computedPoints)) computedPoints = "-";
      return {
        total: computedPoints,
        level: [c1_index, c2_index, c31_index, c32_index, c4_index, c5_index],
        points: [
          c1_points,
          c2_points,
          c31_points,
          c32_points,
          c4_points,
          c5_points,
        ],
      };
    },
    palier() {
      var current_pallier = 0;
      if (isNaN(this.points.total)) return "-";
      for (let i in this.$store.state.palliers) {
        current_pallier = i;
        if (this.points.total <= this.$store.state.palliers[i]) {
          break;
        }
      }
      return parseInt(current_pallier) + 1;
    },
    statut() {
      let current_statut = this.lang.default_statut;

      if (this.points.total >= 74) {
        if (
          this.$store.state.critere1 >= 4 &&
          (this.$store.state.critere2 >= 3 || this.$store.state.critere3 >= 3)
        ) {
          current_statut = this.lang.statut_technicien;
        }
      }

      if (this.points.total >= 105) {
        if (
          this.$store.state.critere2 >= 3 &&
          (this.$store.state.critere4 >= 3 || this.$store.state.critere1 >= 4)
        ) {
          current_statut = this.lang.statut_agent;
        }
      }

      if (this.points.total >= 197) {
        if (
          this.$store.state.critere2 >= 4 &&
          (this.$store.state.critere4 >= 4 || this.$store.state.critere1 >= 4)
        ) {
          current_statut = this.lang.statut_cadre;
        }
      }

      return current_statut;
    },
    remuneration() {
      const euro = new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
      if (this.palier == "-") {
        return "- ";
      }
      return euro.format(this.$store.state.new_remuneration[this.palier-1]);
    },
    old_remuneration() {
      const euro = new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
      if (this.palier == "-") {
        return "- ";
      }
      return euro.format(this.$store.state.old_remuneration[this.palier-1]);
    },
  },
  beforeMount() {
    //send ajax here
    
    if (
      this.$store.state.critere1 == false ||
      this.$store.state.critere2 == false ||
      this.$store.state.critere31 == false ||
      this.$store.state.critere32 == false ||
      this.$store.state.critere4 == false ||
      this.$store.state.critere5 == false
    ) {
      this.$router.push("comment-ca-marche");
    } else {
      if (
        this.$store.state.nom == false ||
        this.$store.state.prenom == false ||
        this.$store.state.email == false ||
        this.$store.state.departement == false
      ) {
        this.$router.push("coordonnees");
      }
    }
    
  },
  mounted() {

    window.scrollTo(0, 0);
    
    let fd = new FormData();
    fd.append("nom", this.$store.state.nom);
    fd.append("prenom", this.$store.state.prenom);
    fd.append("email", this.$store.state.email);
    fd.append("tel", this.$store.state.tel);

    fd.append("points", this.points.total);
    fd.append("palier", this.palier);
    fd.append("statut", this.statut);
    fd.append("remuneration", this.remuneration);

    fd.append("technicite_p", this.points.points[0]);
    fd.append("technicite_d", this.points.level[0]+1);
    fd.append("autonomie_p", this.points.points[1]);
    fd.append("autonomie_d", this.points.level[1]+1);
    fd.append("responsabilite_p", this.points.points[2]+this.points.points[3]);
    fd.append("responsabilite_d", (this.points.level[2]+1) +' - '+ (this.points.level[3]+1));
    fd.append("management_p", this.points.points[4]);
    fd.append("management_d", this.points.level[4]+1);
    fd.append("relationnel_p", this.points.points[5]);
    fd.append("relationnel_d", this.points.level[5]+1);

    if (this.$store.state.tel != "") {
      fd.append("recontact", true);
    } else {
      fd.append("recontact", false);
    }
    fd.append("departement", this.$store.state.departement);

    axios({
      method: "post",
      url: "https://www.salaire-fga-cfdt.fr/api/post.php",
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (response) {
        console.log(response);
      });
      
  },
  methods: {
    /*redirect() {
      window.location =
        "https://www.echanges-fga-cfdt.fr/fcrm-form/production-agricole_cuma_etarf/";
    },*/
  },
};
</script>

<style lang="scss">
.modal .btn-primary {
  color: #fff;
}
</style>